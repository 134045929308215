'use client';

import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';
import { ReactPlayerProps } from 'react-player';

type VideoProps = {
  url: string;
  playing?: boolean;
  loop?: boolean;
  controls?: boolean;
  muted?: boolean;
  width?: string;
  height?: string;
};

const ReactPlayer = dynamic<ReactPlayerProps>(() => import('react-player'), { ssr: false });

export default function LazyVideoBanner({
  url,
  playing,
  loop,
  controls,
  muted,
  width,
  height,
}: VideoProps) {
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) setLoaded(true);
  }, [inView]);

  console.log(width);

  return (
    <div ref={ref}>
      {loaded && (
        <ReactPlayer
          url={url}
          playing={playing}
          loop={loop}
          controls={controls}
          muted={muted}
          width={height}
          height={width}
          style={{ position: 'absolute', top: 0, left: 0 }}
          config={{
            youtube: {
              playerVars: {
                disablekb: 1,
                modestbranding: 1,
                controls: controls ? 1 : 0,
              },
            },
          }}
        />
      )}
    </div>
  );
}
