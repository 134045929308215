import { ButtonVariant, ElementType, GlobalConfig, SectionElement } from '@homeflow/next/state';
import { v4 as uuid } from 'uuid';

export default function generateInitialElement(
  type: ElementType,
  globalConfig: GlobalConfig
): SectionElement {
  switch (type) {
    case ElementType.Heading:
      return {
        id: uuid(),
        type: type,
        html: `New ${type} field`,
        level: 2,
      };
    case ElementType.Link:
      return {
        id: uuid(),
        type: type,
        html: `New ${type} field`,
        url: '/',
        target: '_self',
        buttonVariant: ButtonVariant.Default,
      };
    case ElementType.SearchForm:
      return {
        id: uuid(),
        type: type,
        ...globalConfig.searchForm,
      };
    case ElementType.Image:
      return {
        id: uuid(),
        type: type,
        image: {
          src: '/images/hero-default-0.jpg',
          alt: 'House at twilight',
          width: 200,
          height: 200,
        },
      };
    case ElementType.Social:
      return {
        id: uuid(),
        type,
        social: {
          email: { url: '/', target: '_self' },
          phone: { url: '/', target: '_self' },
          facebook: { url: '/', target: '_self' },
          instagram: { url: '/', target: '_self' },
          x: { url: '/', target: '_self' },
          linkedIn: { url: '/', target: '_self' },
          pinterest: { url: '/', target: '_self' },
        },
      };
    default:
      return {
        id: uuid(),
        type: type,
        html: `New ${type} field`,
      };
  }
}
