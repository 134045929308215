import { getImageProps } from 'next/image';
import { ImageField } from '../state';
import { cmsImage } from '../images';
import { twMerge } from 'tailwind-merge';

interface Props {
  desktopImage: ImageField;
  mobileImage: ImageField;
  mobileWidth?: number;
  mobileHeight?: number;
  priority?: boolean;
  className?: string;
}

/** Renders a different image depending on screen size */
export default function DeviceImage({
  desktopImage,
  mobileImage,
  /** The rendered width of the mobile image */
  mobileWidth = 414,
  /** The rendered height of the mobile image */
  mobileHeight = 350,
  priority = false,
  className = '',
}: Props) {
  return (
    <picture className="h-full w-full">
      <source
        media="(max-width: 414px)"
        srcSet={
          getImageProps({
            src: cmsImage(mobileImage.src) || '',
            height: mobileWidth,
            width: mobileHeight,
            alt: mobileImage.alt,
            priority,
          }).props.srcSet
        }
      />

      <img
        className={twMerge('h-full w-full object-cover', className)}
        {...getImageProps({
          src: desktopImage.src || '',
          fill: true,
          alt: desktopImage.alt || '',
          priority,
        }).props}
        alt="" // alt added twice to keep TS happy
      />
    </picture>
  );
}
