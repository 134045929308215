import ResponsiveImage from '@/lib/components/images/responsive-image';
import {
  CarouselConfig,
  ContainerWidth,
  GenericConfig,
  GlobalConfig,
  ImageField,
  Section,
} from '@/lib/state';
import { twMerge } from 'tailwind-merge';
import Container from '../../../components/container.component';
import SectionElement from '../../section-element.component';
import { SectionItemWrapperProps } from '@/components/section-items/section-item-wrapper.component';
import { ConfigSettingType, ISectionElementWrapper } from '@/lib/sections';
import { FunctionComponent } from 'react';
import initialSlide from './slide-initial-config';
import DeviceImage from '@/lib/utils/device-image';

const generateSlideSettings = () => {
  return [
    {
      label: 'Image',
      type: ConfigSettingType.Image,
      field: `image`,
    },
  ];
};

export default function Slide({
  item,
  slideIndex,
  hasItemWithElements,
  section,
  SectionElementWrapper,
  editMode,
  globalConfig,
  SectionItemWrapper,
}: {
  editMode?: boolean;
  item: GenericConfig;
  slideIndex: number;
  hasItemWithElements: boolean;
  section: Section<CarouselConfig> & {
    areaName: string;
  };
  globalConfig?: GlobalConfig;
  SectionItemWrapper?: FunctionComponent<SectionItemWrapperProps>;
  SectionElementWrapper?: ISectionElementWrapper;
}) {
  const itemElements =
    item.elements.length > 0 ? (
      <div className="flex flex-col gap-9 w-full p-9 items-start">
        {item.elements?.map((element, elementIndex) => (
          <SectionElement
            key={element.id}
            element={element}
            elementIndex={elementIndex}
            section={section}
            baseClassName="rigel-image-carousel"
            editMode={editMode}
            itemIdChain={[item.id]}
            globalConfig={globalConfig}
            SectionElementWrapper={SectionElementWrapper}
          />
        ))}
      </div>
    ) : null;

  return (
    <div
      className={twMerge(
        'rigel-image-carousel-slide-text__slide relative shrink-0 h-full basis-full flex flex-col justify-center items-center gap-5',
        hasItemWithElements && 'pb-9'
      )}
    >
      {item.image && (
        <>
          {item.mobileImage ? (
            <DeviceImage
              desktopImage={item.image as ImageField}
              mobileImage={item.mobileImage}
              priority={slideIndex < 3}
              className={twMerge(
                'absolute object-cover w-full h-auto',
                hasItemWithElements && 'pb-9'
              )}
            />
          ) : (
            <ResponsiveImage
              image={item.image as ImageField}
              options={{
                priority: slideIndex < 3,
              }}
              className={twMerge(
                'absolute object-cover w-full h-auto',
                hasItemWithElements && 'pb-9'
              )}
            />
          )}

          <div
            className={twMerge(
              'rigel-image-carousel-slide-text__background-overlay z-0 absolute top-0 left-0 block w-full h-full',
              hasItemWithElements && 'pb-9'
            )}
          >
            <div
              className="h-full w-full bg-black"
              style={{ opacity: section.configuration?.overlayOpacity ?? 0.5 }}
            />
          </div>

          {item.elements.length > 0 && (
            <Container containerWidth={ContainerWidth.Medium} className="h-full">
              <div className="rigel-image-carousel-slide-text__text-overlay z-20 absolute -bottom-9 left-0 block bg-striped @lg:max-w-[50%]">
                {SectionItemWrapper ? (
                  <SectionItemWrapper
                    item={item}
                    itemIdChain={[item.id]}
                    generateSettings={generateSlideSettings}
                    section={section}
                    sectionConfig={section.configuration}
                    defaultInitialItem={initialSlide()}
                  >
                    {itemElements}
                  </SectionItemWrapper>
                ) : (
                  itemElements
                )}
              </div>
            </Container>
          )}
          {item.elements.length === 0 && SectionItemWrapper && (
            <Container containerWidth={ContainerWidth.Medium} className="h-full">
              <div className="z-20 absolute bottom-0 left-0 block w-80 h-40">
                <SectionItemWrapper
                  item={item}
                  itemIdChain={[item.id]}
                  generateSettings={generateSlideSettings}
                  section={section}
                  sectionConfig={section.configuration}
                  defaultInitialItem={initialSlide()}
                >
                  <div className="h-full w-full" />
                </SectionItemWrapper>
              </div>
            </Container>
          )}
        </>
      )}
    </div>
  );
}
