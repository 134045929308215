import { ItemsSchema, SectionSchema, Elements } from '@homeflow/next/sections';
import { ElementType, GenericConfig, MixedSection, SectionElement } from '@homeflow/next/state';
import { getItem } from './get-item';

const countElementsOfType = (elements: SectionElement[], type: ElementType): number => {
  return elements?.filter((el: SectionElement) => el.type === type).length || 0;
};

const isAtElementLimit = ({
  type,
  elements,
  sectionConfig,
  sectionSchema,
  itemIdChain,
}: {
  type: ElementType;
  elements: SectionElement[];
  sectionConfig: MixedSection;
  sectionSchema: SectionSchema;
  itemIdChain?: string[];
}) => {
  let limit = 0;
  if (itemIdChain === undefined) {
    limit = sectionSchema?.elements[type]?.limit || 0;
  } else {
    limit =
      itemIdChain.reduce((schema: SectionSchema | ItemsSchema, _unused) => {
        return schema?.items as ItemsSchema;
      }, sectionSchema)?.elements[type]?.limit || 0;
  }
  if (limit === 0) return true;

  const item = getItem(sectionConfig.items as GenericConfig[], itemIdChain);

  const count = item
    ? countElementsOfType(item.elements, type)
    : countElementsOfType(elements, type);
  return count >= limit;
};

export default isAtElementLimit;
