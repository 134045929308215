'use client';

import { SyntheticEvent, useContext, useMemo } from 'react';
import {
  ElementType,
  MixedSection,
  Section,
  SectionElement,
  selectGlobalConfig,
  selectElements,
  selectSectionSchemas,
  selectSelectedElement,
  setField,
  setSelection,
  selectPreviewMode,
} from '@homeflow/next/state';
import { useSelector, useDispatch } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import isAtElementLimit from '@/utils/is-at-element-limit';
import ElementBaseControlButtons from '@admin/components/buttons/element-base-control-buttons.component';
import generateInitialElement from '@/lib/default-configs/generate-initial-element';
import { SectionSchema } from '@homeflow/next/sections';
import { omitNonSerializableFromSchema } from '@/utils/omit-non-serializable-from-schemas';
import SectionModalContext from '@/app/admin/(dashboard)/pages/components/section-modal-context';

/**
 *
 * A wrapper component for section elements that provides functionality required in the admin page builder.
 * Features include adding / deleting / reording elements.
 */

export default function SectionElementWrapper({
  editMode,
  element,
  elementIndex,
  children,
  section,
  itemIdChain,
}: {
  editMode?: boolean;
  element: SectionElement;
  elementIndex: number;
  children: React.ReactNode;
  section: Section<MixedSection> & { areaName: string };
  itemIdChain?: string[];
}): JSX.Element {
  const {
    openAddElementModal,
    onOpenAddElement,
    onCloseAddElement,
    handleSetOnAdd,
    handleSetElementLimits,
  } = useContext(SectionModalContext);
  const previewMode = useSelector(selectPreviewMode);

  const elements = useSelector(
    selectElements(section.areaName, section.id, itemIdChain)
  ) as SectionElement[];
  const selectedElement = useSelector(selectSelectedElement);
  const globalConfig = useSelector(selectGlobalConfig);
  const dispatch = useDispatch();
  const sectionSchemas = useSelector(selectSectionSchemas);

  // get the relevant section schema from redux to use when checking element limits
  const sectionSchema = sectionSchemas[
    section.component as keyof typeof sectionSchemas
  ] as SectionSchema;

  const handleInsertElement = (type: ElementType) => {
    const tempElements: SectionElement[] = elements ? [...elements] : [];

    tempElements.splice(elementIndex + 1, 0, generateInitialElement(type, globalConfig));

    dispatch(
      setField({
        nodeAddress: {
          areaName: section.areaName,
          sectionId: section.id,
          itemIdChain,
        },
        field: 'elements',
        data: tempElements,
      })
    );

    onCloseAddElement();
  };

  const handleDeleteElement = (e: SyntheticEvent) => {
    e.stopPropagation();
    const tempElements = elements ? [...elements] : [];
    tempElements.splice(elementIndex, 1);
    dispatch(
      setField({
        nodeAddress: {
          areaName: section.areaName,
          sectionId: section.id,
          itemIdChain,
        },
        field: 'elements',
        data: tempElements,
      })
    );

    if (!itemIdChain) {
      dispatch(
        setSelection({
          address: {
            areaName: section.areaName,
            sectionId: section.id,
          },
          sectionComponent: section.component,
          settings: sectionSchema.configSettings,
          sectionschema: omitNonSerializableFromSchema(sectionSchema),
        })
      );
    }
  };

  const handleSelectElement = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      setSelection({
        address: {
          areaName: section.areaName,
          sectionId: section.id,
          itemIdChain,
          elementId: element.id,
        },
      })
    );
  };

  const handleReorderElement = (e: SyntheticEvent, position: 'prev' | 'next') => {
    e.stopPropagation();
    const newValues = [...elements];
    const [splicedItem] = newValues.splice(elementIndex, 1);
    const positionIndex = position === 'prev' ? elementIndex - 1 : elementIndex + 1;
    newValues.splice(positionIndex, 0, splicedItem);
    dispatch(
      setField({
        nodeAddress: {
          areaName: section.areaName,
          sectionId: section.id,
          itemIdChain,
        },
        field: 'elements',
        data: newValues,
      })
    );
  };

  // Calculate if element limits reached
  const elementLimits = useMemo(() => {
    return (Object.values(ElementType) as Array<ElementType>).reduce(
      (accumulator, current) => ({
        ...accumulator,
        [current]: isAtElementLimit({
          elements,
          sectionConfig: section.configuration,
          sectionSchema,
          itemIdChain,
          type: current,
        }),
      }),
      {} as { [key in ElementType]: boolean }
    );
  }, [elements, section.configuration, itemIdChain, sectionSchema]);

  const handleOpen = () => {
    handleSetElementLimits(elementLimits);
    handleSetOnAdd(handleInsertElement);
    onOpenAddElement();
  };

  if (previewMode) return <>{children}</>;

  return (
    <>
      <div
        onClick={(e) => handleSelectElement(e)}
        className={twMerge(
          '!flex hover:!outline-hf-button-blue hover:!outline hover:!outline-1 group/element-base !relative',
          selectedElement?.id === element.id &&
            '!outline !outline-2 hover:!outline-2 !outline-hf-button-blue'
        )}
      >
        <div className="!relative group !p-1 !w-full">
          {editMode && !openAddElementModal && (
            <div className="hidden group-hover/element-base:flex !w-full !absolute !justify-center !-bottom-7 left-1/2 -translate-x-1/2 !z-[110]">
              <ElementBaseControlButtons
                onReorder={handleReorderElement}
                onAdd={handleOpen}
                onDelete={handleDeleteElement}
                disablePrev={elementIndex === 0}
                disableNext={elementIndex === elements.length - 1}
                disableAdd={Object.values(elementLimits).every((value) => value)}
                controlType="element"
              />
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  );
}
