import { twMerge } from 'tailwind-merge';
import { LuPlus, LuCornerUpLeft, LuCornerDownRight, LuTrash2, LuCopyPlus } from 'react-icons/lu';
import { SyntheticEvent } from 'react';

const ADD_BUTTON_CLASSES =
  '!bg-gray-500 hover:!bg-hf-action !p-2 !text-white disabled:!bg-gray-400';

type Props = {
  onReorder: (e: SyntheticEvent, position: 'prev' | 'next') => void;
  onAdd: () => void;
  onDelete: (e: SyntheticEvent) => void;
  onDuplicate?: () => void;
  disablePrev: boolean;
  disableNext: boolean;
  disableAdd: boolean;
  className?: string;
  controlType: string;
};

// In order to show the controls on hover without impacting other group-hover elements,
// pass a class on to create a unique group to connect to a container class
// Eg. "group/element-base" connects to "group-hover/element-base:flex"
export default function ElementBaseControlButtons({
  onReorder,
  onAdd,
  onDelete,
  onDuplicate,
  disablePrev,
  disableNext,
  disableAdd,
  className,
  controlType,
}: Props) {
  return (
    <span
      className={twMerge(
        'element-base-control-buttons !rounded !bg-gray-500 !flex !z-[110] !drop-shadow-md',
        className
      )}
    >
      <button
        type="button"
        className={twMerge(ADD_BUTTON_CLASSES, '!rounded-bl !rounded-tl')}
        title={`Move this ${controlType} to the previous position`}
        onClick={(e) => onReorder(e, 'prev')}
        disabled={disablePrev}
      >
        <LuCornerUpLeft />
      </button>
      <button
        type="button"
        className={twMerge(ADD_BUTTON_CLASSES)}
        title={`Move this ${controlType} to the next position`}
        onClick={(e) => onReorder(e, 'next')}
        disabled={disableNext}
      >
        <LuCornerDownRight />
      </button>
      <button
        type="button"
        className={twMerge(ADD_BUTTON_CLASSES)}
        title={`Delete this ${controlType}`}
        onClick={onDelete}
      >
        <LuTrash2 />
      </button>
      <button
        type="button"
        className={twMerge(ADD_BUTTON_CLASSES, '!rounded-br !rounded-tr')}
        title={
          disableAdd
            ? `No more ${controlType}s can be added`
            : `Insert a new ${controlType} after this one`
        }
        onClick={onAdd}
        disabled={disableAdd}
      >
        <LuPlus />
      </button>
      {onDuplicate && (
        <button
          type="button"
          className={twMerge(ADD_BUTTON_CLASSES, '!rounded-br !rounded-tr')}
          title={
            disableAdd
              ? `No more ${controlType}s can be added`
              : `Duplicate ${controlType} after this one`
          }
          onClick={onDuplicate}
          disabled={disableAdd}
        >
          <LuCopyPlus />
        </button>
      )}
    </span>
  );
}
