'use client';

import { Fragment, PropsWithChildren } from 'react';

import { SectionProps } from '@/lib/sections';
import ResponsiveImage from '@/lib/components/images/responsive-image';
import { GenericConfig, CarouselConfig, ContainerWidth } from '@/lib/state';
import DeviceImage from '@/lib/utils/device-image';

import Container from '@rigel/components/container.component';
import Slider from '@rigel/components/carousels/slider.component';
import SectionElement from '@rigel/sections/section-element.component';
import { twMerge } from 'tailwind-merge';

export default function ImageCarouselSectionText({
  editMode,
  section,
  globalConfig,
  SectionElementWrapper,
}: PropsWithChildren & SectionProps<CarouselConfig>) {
  const hasElements = section.configuration.elements.length > 0;
  const height = section.configuration.height;

  return (
    <>
      {section.configuration.items && (
        <div className={twMerge('image-carousel relative mx-auto', hasElements && 'mb-9')}>
          <Slider
            autoPlay
            delay={section.configuration.delay}
            className={twMerge(
              'relative overflow-hidden h-full text-white z-10',
              height === 'small' && 'max-h-[50svh] @md:max-h-none h-64 @sm:h-[400px]',
              (height === 'medium' || !height) && 'max-h-[70svh] @md:max-h-none h-96 @sm:h-[600px]',
              height === 'large' && 'max-h-[90svh] @md:max-h-none h-[40rem] @sm:h-[800px]'
            )}
          >
            {section.configuration.items.map((item, imageIndex) => {
              const genericItem = item as GenericConfig;

              return (
                <Fragment key={imageIndex}>
                  {genericItem.image && (
                    <div className="rigel-image-carousel-section-text__slide relative shrink-0 h-full basis-full flex flex-col justify-center items-center gap-5">
                      {genericItem.mobileImage ? (
                        <DeviceImage
                          desktopImage={genericItem.image}
                          mobileImage={genericItem.mobileImage}
                          priority={imageIndex < 3}
                        />
                      ) : (
                        <ResponsiveImage
                          image={genericItem.image}
                          options={{
                            priority: imageIndex < 3,
                          }}
                        />
                      )}

                      <div
                        className="rigel-image-carousel-section-text__background-overlay z-0 absolute top-0 left-0 block w-full h-full bg-black"
                        style={{ opacity: section.configuration?.overlayOpacity ?? 0.5 }}
                      />
                    </div>
                  )}
                </Fragment>
              );
            })}
          </Slider>

          {hasElements && (
            <Container containerWidth={ContainerWidth.Medium} className="px-0">
              <div className="rigel-image-carousel-section-text__text-overlay z-20 @sm:absolute -bottom-9 left-0 block bg-striped @lg:max-w-[50%]">
                <div className="flex flex-col gap-9 w-full p-9 items-start">
                  {section.configuration.elements.map((element, elementIndex) => (
                    <SectionElement
                      key={element.id}
                      element={element}
                      elementIndex={elementIndex}
                      section={section}
                      baseClassName="rigel-image-carousel-section-text"
                      editMode={editMode}
                      globalConfig={globalConfig}
                      SectionElementWrapper={SectionElementWrapper}
                    />
                  ))}
                </div>
              </div>
            </Container>
          )}
        </div>
      )}

      {editMode && !section.configuration.items && <span>Need to add some config items</span>}
    </>
  );
}
