/* WHEN CHANGING THE HEADING CLASSES ALSO CHANGE THE CLASSES IN THE HEADING COMPONENT */

const PROSE_CLASSES =
  'prose prose-headings:mt-0 prose-headings:font-normal \
  prose-h1:mb-4 prose-h1:text-primary-text prose-h1:text-2xl @md:prose-h1:text-3xl @lg:prose-h1:text-4xl \
  prose-h2:mb-4 prose-h2:text-primary prose-h2:text-xl @md:prose-h2:text-2xl \
  prose-h3:mb-2 prose-h3:text-primary \
  prose-h5:mb-2 prose-h5:text-primary \
  prose-h6:mb-2 prose-h6:text-primary \
  prose-strong:text-inherit prose-a:text-inherit';

export default PROSE_CLASSES;
