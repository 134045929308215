'use client';

import dynamic from 'next/dynamic';
import { SectionProps } from '@homeflow/next/sections';
import { VideoBannerConfig } from '@homeflow/next/state';
import SectionElement from '../section-element.component';
import Container from '../../components/container.component';
import SectionElementWrapper from '@/components/section-elements/section-element-wrapper.component';
import LazyVideoBanner from './lazy-video-banner.component';

export default function VideoBanner({
  editMode,
  section,
  globalConfig,
}: SectionProps<VideoBannerConfig>) {
  return (
    <Container containerWidth={section.configuration.containerWidth}>
      <div className="rigel-video-banner relative mx-auto overflow-hidden group/sectionConfig">
        <div
          className="rigel-video-banner__background-overlay z-10 absolute top-0 left-0 block w-full h-full bg-black"
          style={{ opacity: section.configuration?.overlayOpacity ?? 0.5 }}
        />
        <div className="absolute top-0 w-full h-full z-10">
          <div className="flex flex-col h-full justify-center gap-5 w-full text-center">
            {section.configuration.elements.map((element, index) => (
              <SectionElement
                key={element.id}
                element={element}
                elementIndex={index}
                section={section}
                baseClassName="rigel-video-banner"
                editMode={editMode}
                globalConfig={globalConfig}
                SectionElementWrapper={SectionElementWrapper}
              />
            ))}
          </div>
        </div>
        <div className="relative pt-[56.25%]">
          <LazyVideoBanner
            url={section.configuration.src}
            playing={section.configuration.autoplay}
            loop={section.configuration.loop}
            controls={section.configuration.controls}
            muted={section.configuration.muted}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </Container>
  );
}
